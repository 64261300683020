import styled from '@emotion/styled';
import { Container, FlexRow } from '../modules/grid';
import { Logo } from './Logo';
import { Menu } from './Menu';

export const PageHeader: React.FC = () => {
  return (
    <StyledPageHeader>
      <Container>
        <FlexRow justify="space-between">
          <Logo />
          <Menu />
        </FlexRow>
      </Container>
    </StyledPageHeader>
  );
};

const StyledPageHeader = styled.div`
  width: 100%;
  padding: 1rem 0;
`;
