import styled from '@emotion/styled';
import React from 'react';
import { color } from '../../config';
import { transition } from '../../helper';
import { EmotionHtmlProps } from '../../ui.types';

interface ITextboxProps extends EmotionHtmlProps<HTMLInputElement> {
  label: string;
}

const GetTextInputComponent: (InputComponent: React.FC) => React.FC<ITextboxProps> =
  (InputComponent) =>
  ({ label, ...props }) => {
    if (!props.placeholder) props.placeholder = label;
    return (
      <div>
        <InputComponent {...props} />
        <StyledLabel>{label}</StyledLabel>
      </div>
    );
  };

const StyledInput = styled.input`
  display: block;
  width: 100%;
  padding: 1.2rem 0.5rem 0.3rem;
  border: none;
  background: ${color.ui.bg};

  font: inherit;
  outline: none;

  opacity: 1;
  box-shadow: 0 0 0 0 transparent;
  ${transition('.15s', 'box-shadow', 'opacity')};

  &:focus {
    box-shadow: 0 0 0 2px ${color.brand.primary};
  }

  &:disabled {
    opacity: 0.5;
  }

  // make placeholder invisible, but don't hide it completely, so the label transformation works properly
  &::placeholder {
    color: transparent;
  }
`;

const InputAsTextarea = StyledInput.withComponent('textarea');
const StyledTextarea = styled(InputAsTextarea)`
  min-height: 10rem;
  resize: vertical;
`;

const StyledLabel = styled.label`
  display: block;
  position: absolute;
  top: 0.7rem;
  left: 0.5rem;
  max-width: calc(100% - 1rem);
  z-index: 10;
  pointer-events: none;

  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${color.text.secondary};

  transform: none;
  transform-origin: left center;
  transition: transform 0.15s, max-width 0.15s;

  // transform label when input is focused or filled
  input:focus + &,
  input:not(:placeholder-shown) + &,
  textarea:focus + &,
  textarea:not(:placeholder-shown) + & {
    max-width: calc((100% - 1rem) * 4 / 3);
    transform: translateY(-0.6rem) scale(0.75);
  }
`;

export const Textbox: React.FC<ITextboxProps> = GetTextInputComponent(StyledInput);
export const Textarea: React.FC<ITextboxProps> = GetTextInputComponent(StyledTextarea);
