import styled from '@emotion/styled';
import { HTMLProps } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import logo from '../../img/logo_piotrowski.svg';

export const Logo: React.FC<HTMLProps<HTMLAnchorElement>> = (props) => {
  const scrollToTop = (ev: any) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    if (props.onClick) props.onClick(ev);
  };

  const linkProps = {
    ...props,
    to: '/',
    onClick: scrollToTop,
  } as LinkProps;
  return (
    <LogoLink {...linkProps}>
      <StyledLogo src={logo} alt="logo" />
    </LogoLink>
  );
};

const LogoLink = styled(Link)`
  display: block;
`;

const StyledLogo = styled.img`
  display: block;
  max-height: 48px;
  width: 118px;
  height: 48px;
`;
