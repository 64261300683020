/**
 * Fetch data from an endpoint.
 * @param url - url to fetch from
 * @param init - config object (same as second param in fetch)
 */
export function readJson<T>(url: string, init?: RequestInit) {
  const fetchConfig: RequestInit = {
    ...init,
  };
  return fetch(url, fetchConfig).then(parseResponseAsJson) as Promise<T>;
}

/**
 * Post data to an endpoint.
 * @param url - url to fetch from
 * @param init - config object (same as second param in fetch)
 */
export function postJson<TBody, TReturn>(url: string, body: TBody, init?: RequestInit) {
  const fetchConfig: RequestInit = {
    method: 'post',
    body: JSON.stringify(body),
    ...init,
    headers: getHeaders(init),
  };
  return fetch(url, fetchConfig).then(parseResponseAsJson) as Promise<TReturn>;
}

/**
 * Get default headers to fetch json
 * @param init - config object (same as second param in fetch)
 */
const getHeaders = (init?: RequestInit) => ({
  ...init?.headers,
  Accept: 'application/json',
  'Content-Type': 'application/json',
});

/**
 * Parse fetch response as json.
 * Also parses json bodies for failed requests.
 * @param res - fetch response
 */
export const parseResponseAsJson = (res: Response, isErrorResponse = false) => {
  if (!isErrorResponse && !res.ok) return Promise.reject(res);
  if (res.status !== 204 && res.body) return res.json();
  return res;
};
