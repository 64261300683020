import styled from '@emotion/styled';
import { useEffect, useRef } from 'react';
import { transition } from '../helper';
import { ISectionProps, Section } from '../modules/grid';

export const SectionAnimated: React.FC<ISectionProps> = (props) => {
  const ref = useRef<HTMLDivElement>(null);

  // observer callback; make element visible
  const onIntersection = (entries: IntersectionObserverEntry[]) => {
    for (const entry of entries) {
      if (entry.isIntersecting) entry.target.classList.add('js--in-view');
    }
  };

  // create intersection observer
  useEffect(() => {
    if (!ref.current) return;

    // fallback if IntersectionObserver is not supported
    if (
      !(
        'IntersectionObserver' in window &&
        'IntersectionObserverEntry' in window &&
        'intersectionRatio' in window.IntersectionObserverEntry.prototype
      )
    ) {
      ref.current.classList.add('js--in-view');
      return;
    }

    const refCurrent = ref.current;
    const observer = new IntersectionObserver(onIntersection, { threshold: [0.2] });
    observer.observe(refCurrent);

    return () => observer.unobserve(refCurrent);
  }, [ref]);

  // return supercharged section
  return <StyledScrollIntoView ref={ref} {...props} />;
};

const StyledScrollIntoView = styled(Section)`
  opacity: 0;
  transform: translateY(2rem);
  ${transition('.6s', 'opacity', 'transform')};

  &.js--in-view {
    opacity: 1;
    transform: translateY(0);
  }
`;
