import { css } from '@emotion/react';

export const breakpoint = {
  mobile: '480px',
  tablet: '768px',
  desktop: '1200px',
};

export const color = {
  text: {
    primary: 'hsl(0, 0%, 13%)',
    secondary: 'hsl(0, 0%, 57%)',
    meta: 'hsl(0, 0%, 68%)',
    light: 'hsl(0, 0%, 100%)',
  },
  brand: {
    primary: 'hsl(51, 91%, 38%)',
  },
  ui: {
    bg: 'hsl(0, 0%, 93%)',
  },
};

export const font = {
  // base settings for body font. Font size will be overwritten with 18px for tabets and 20px for desktops
  body: css`
    font: 400 16px/1.7em 'Inter', 'Helvetica Neue', 'Helvetica', sans-serif;
    letter-spacing: -0.01em;
  `,
  h1: css`
    font-weight: 700;
    font-size: 3rem; // mobile: 3.375rems
    letter-spacing: -0.025em;
    line-height: 1.5em;
  `,
  h2: css`
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 1.5em;
    letter-spacing: -0.025em;
  `,
  h3: css`
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.5em;
    letter-spacing: -0.025em;
  `,
  h4: css`
    font-weight: 700;
    font-size: 1rem;
  `,
  bigger: css`
    font-size: 1.2rem;
    line-height: 1.7rem;
  `,
  small: css`
    font-weight: 600;
    font-size: 0.9rem;
  `,
  smaller: css`
    font-weight: 600;
    font-size: 0.8rem;
  `,
};
