import { Global } from '@emotion/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { LandingpageView } from './landingpage/LandingpageView';
import { ImprintView } from './legal/ImprintView';
import reportWebVitals from './reportWebVitals';
import { PageFooter } from './ui/components/PageFooter';
import { PageHeader } from './ui/components/PageHeader';
import { globalStyles } from './ui/global';
import smoothscroll from 'smoothscroll-polyfill';
import { CompetencePage } from './pages/CompetencePage';
import { HelmetProvider } from 'react-helmet-async';
import { DataPrivacyView } from './legal/DataPrivacyView';

smoothscroll.polyfill();

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <Global styles={globalStyles} />

        <PageHeader />

        <Switch>
          <Route exact path="/" component={LandingpageView} />
          <Route exact path="/impressum" component={ImprintView} />
          <Route exact path="/datenschutz" component={DataPrivacyView} />
          <Route exact path="/kompetenz/:id" component={CompetencePage} />
        </Switch>

        <PageFooter />
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
