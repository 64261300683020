import styled from '@emotion/styled';
import { color, font } from '../config';
import { EmotionHtmlProps } from '../ui.types';

export const Button: React.FC<EmotionHtmlProps<HTMLButtonElement>> = (props) => {
  return <StyledButton {...props} />;
};

const StyledButton = styled.button`
  font: inherit;
  border: none;
  outline: none;
  box-shadow: none;

  display: block;
  padding: 0.6rem 1.3rem;
  ${font.smaller};

  color: ${color.text.light};
  background-color: ${color.text.primary};
  cursor: pointer;
  user-select: none;

  transition: background-color 0.1s, opacity 0.15s;

  &:hover,
  &:focus {
    background-color: ${color.brand.primary};
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;
