import React from 'react';
import { H3, StyledA } from '../ui/modules/atoms';
import { EmotionHtmlProps } from '../ui/ui.types';

export const QmItem: React.FC<EmotionHtmlProps<HTMLAnchorElement>> = ({ children, ...props }) => {
  return (
    <H3 className="m-top_lg">
      <StyledA {...props} target="_blank" rel="noopener noreferrer">
        {children}
      </StyledA>
    </H3>
  );
};
