import { useCmsData } from '../cms/useCmsData';
import { ContactInfoDto } from '../landingpage/landingpage.types';
import { SectionAnimated } from '../ui/components/SectionAnimated';
import { EmptyState, H2, Secondary } from '../ui/modules/atoms';
import { Subsection, TextContainer, Section } from '../ui/modules/grid';
import { BasicState } from '../util/useStateMachine';
import { ContactTabs } from './ContactTabs';

interface ContactSectionProps {}

export const ContactSection: React.FC<ContactSectionProps> = () => {
  const [contactInfo, { state }] = useCmsData<ContactInfoDto>(`/contact-info`);

  if (state !== BasicState.Success)
    return (
      <Section id="beauftragung">
        <EmptyState>Lade Kontaktformular...</EmptyState>
      </Section>
    );

  return (
    <SectionAnimated id="beauftragung">
      <Subsection>
        <H2 withUnderline>Beauftragung</H2>
        <Secondary>
          <TextContainer>{contactInfo.intro_landingpage}</TextContainer>
        </Secondary>
      </Subsection>

      <ContactTabs />
    </SectionAnimated>
  );
};
