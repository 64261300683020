import styled from '@emotion/styled';
import { breakpoint } from '../config';
import { media } from '../helper';
import { EmotionHtmlProps } from '../ui.types';

export const Container = styled.div`
  width: 90%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;

export interface ISectionProps extends EmotionHtmlProps<HTMLDivElement> {
  first?: boolean;
}

const rem = (val: number) => val + 'rem';
const getSectionStyling = (margin: number, first?: boolean) => `
  margin-top: ${rem(first ? margin / 2 : margin)};
  margin-bottom: ${rem(margin)};
`;

export const Section = styled.section<ISectionProps>`
  ${(props) => getSectionStyling(4, props.first)};

  ${media(breakpoint.tablet)} {
    ${(props) => getSectionStyling(6, props.first)};
  }

  ${media(breakpoint.desktop)} {
    ${(props) => getSectionStyling(8, props.first)};
  }
`;

export const Subsection = styled.section<{ first?: boolean }>`
  margin-top: ${(props) => (props.first ? '1rem' : '2rem')};
  margin-bottom: 2rem;
`;

export const TextContainer = styled.div`
  max-width: 42rem;
`;

const getMediaShowCss = (bp: string, visible: boolean) => `
  ${media(bp)} {
    display: ${visible ? 'block' : 'none'};
  }
`;
export const ResponsiveShow = styled.div<{
  initial?: boolean;
  mobile?: boolean;
  tablet?: boolean;
  desktop?: boolean;
}>`
  ${(props) => {
    let css = '';
    if (!props.initial) css += 'display: none;';
    // mobile
    if (!props.initial && props.mobile) css += getMediaShowCss(breakpoint.mobile, true);
    if (props.initial && !props.mobile) css += getMediaShowCss(breakpoint.mobile, false);
    // tablet
    if (!props.mobile && props.tablet) css += getMediaShowCss(breakpoint.tablet, true);
    if (props.mobile && !props.tablet) css += getMediaShowCss(breakpoint.tablet, false);
    // desktop
    if (!props.tablet && props.desktop) css += getMediaShowCss(breakpoint.desktop, true);
    if (props.tablet && !props.desktop) css += getMediaShowCss(breakpoint.desktop, false);

    return css;
  }}
`;

export interface IFlexRowProps {
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  align?: 'flex-start' | 'center' | 'flex-end' | 'space-around' | 'space-between';
  justify?: 'flex-start' | 'center' | 'flex-end' | 'space-around' | 'space-between';
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  gap?: string;
}

/**
 * Default params: row, center, flex-start, nowrap
 */
export const FlexRow = styled.div<IFlexRowProps>`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  align-items: ${(props) => props.align || 'center'};
  justify-content: ${(props) => props.justify || 'flex-start'};
  flex-wrap: ${(props) => props.wrap || 'nowrap'};
  gap: ${(props) => props.gap || '0'};
`;
