import { ResponsiveShow, Subsection, FlexRow } from '../ui/modules/grid';
import { TabContainer, Tab } from '../ui/modules/tabs';
import { ContactForm } from './ContactForm';
import { ContactInfo } from './ContactInfo';

export const ContactTabs: React.FC = () => {
  return (
    <>
      <ResponsiveShow initial mobile>
        <Subsection>
          <TabContainer tabLabels={['Kontaktformular', 'Kontaktdaten']}>
            <Tab>
              <ContactForm />
            </Tab>
            <Tab>
              <ContactInfo />
            </Tab>
          </TabContainer>
        </Subsection>
      </ResponsiveShow>

      <ResponsiveShow tablet desktop>
        <Subsection>
          <FlexRow align="flex-start" justify="space-between" gap="4rem">
            <ContactForm />
            <ContactInfo />
          </FlexRow>
        </Subsection>
      </ResponsiveShow>
    </>
  );
};
