import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { color, font } from '../config';
import { transition } from '../helper';
import { H2, StyledLink } from '../modules/atoms';
import { Container, FlexRow, ResponsiveShow } from '../modules/grid';
import { Logo } from './Logo';

export const Menu: React.FC = () => {
  const [open, setOpen] = useState(false);

  const closeMenu = () => {
    console.log('close');
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [open]);

  return (
    <>
      <ResponsiveShow initial mobile>
        <MenuButton open={open} onClick={() => setOpen(!open)} type="button" aria-label="Open menu">
          <MenuButtonBar open={open} type="top" />
          <MenuButtonBar open={open} type="bottom" />
        </MenuButton>

        <MenuOverlay open={open}>
          <MenuLogo onClick={closeMenu} />
          <Container>
            <MenuLink to="/#taetigkeitsschwerpunkte" onClick={closeMenu}>
              Schwerpunkte
            </MenuLink>
            <MenuLink to="/#zur-person" onClick={closeMenu}>
              Zur Person
            </MenuLink>
            <MenuLink to="/#qualitaetssicherung" onClick={closeMenu}>
              Qualitätssicherung
            </MenuLink>
            <MenuLink to="/#beauftragung" onClick={closeMenu}>
              Beauftragung
            </MenuLink>
          </Container>
        </MenuOverlay>
      </ResponsiveShow>

      <ResponsiveShow tablet desktop>
        <FlexRow gap="1rem">
          <DesktopMenuLink to="/#taetigkeitsschwerpunkte">Schwerpunkte</DesktopMenuLink>
          <DesktopMenuLink to="/#zur-person">Zur Person</DesktopMenuLink>
          <DesktopMenuLink to="/#qualitaetssicherung">Qualitätssicherung</DesktopMenuLink>
          <DesktopMenuLink to="/#beauftragung">Beauftragung</DesktopMenuLink>
        </FlexRow>
      </ResponsiveShow>
    </>
  );
};

const MenuButton = styled('button')<{ open: boolean }>`
  position: fixed;
  top: 1.3rem;
  right: 5%;
  width: 36px;
  height: 36px;
  z-index: 101;
  cursor: pointer;

  display: block;
  border: none;
  background: none;
  box-shadow: none;
  outline: none;
`;

/**
 * One bar of the menu icon. Rotates 45deg when the menu is opened
 */
const MenuButtonBar = styled.div<{ type: string; open: boolean }>`
  position: absolute;
  top: 11px;
  left: 0;
  width: 100%;
  height: 3px;
  background: ${color.text.primary};

  transition: transform 0.2s;
  transform-origin: 27% center;

  // default - bottom bar
  ${(props) =>
    props.type === 'bottom' &&
    css`
      top: auto;
      bottom: 11px;
    `}

  // open - top bar
  ${(props) =>
    props.open &&
    props.type === 'top' &&
    css`
      transform: rotate(45deg);
    `}

  // open - bottom bar
  ${(props) =>
    props.open &&
    props.type === 'bottom' &&
    css`
      transform: rotate(-45deg);
    `}
`;

const MenuOverlay = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  background: ${color.text.light};

  opacity: 0;
  visibility: hidden;
  ${transition('.2s', 'opacity', 'visibility')};

  ${(props) =>
    props.open &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

const MenuLogo = styled(Logo)`
  position: absolute;
  top: 1rem;
  left: 5%;
`;

const MenuLink: React.FC<LinkProps> = ({ onClick, ...props }) => {
  return (
    <StyledMenuLink onClick={onClick as any}>
      <StyledLink {...props} />
    </StyledMenuLink>
  );
};

const StyledMenuLink = styled(H2)`
  margin: 3rem 0;
`;

const DesktopMenuLink = styled(Link)`
  ${font.small};
  text-decoration: none;

  color: ${color.text.meta};
  transition: color 0.1s;

  &:hover {
    color: ${color.text.primary};
  }
`;
