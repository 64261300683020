import styled from '@emotion/styled';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useCmsData } from '../cms/useCmsData';
import { ContactSection } from '../contact/ContactSection';
import portrait from '../img/portrait.webp';
import { BackToTop } from '../ui/components/BackToTop';
import { ScrollDownArrow } from '../ui/components/ScrollDownArrow';
import { SectionAnimated } from '../ui/components/SectionAnimated';
import { breakpoint } from '../ui/config';
import { media } from '../ui/helper';
import { EmptyState, H1, H2, Secondary } from '../ui/modules/atoms';
import { Container, Section, TextContainer } from '../ui/modules/grid';
import { BasicState } from '../util/useStateMachine';
import { LandingpageData, LandingpageResponse } from './landingpage.types';
import { NumberedLink } from './NumberedLink';
import { QmItem } from './QmItem';
import { VitaItem } from './VitaItem';

const mapLandingpageResponse = (data: LandingpageResponse) => {
  return {
    ...data,
    competences: data.competences.data.map((c) => ({ ...c.attributes, id: c.id })),
  } as LandingpageData;
};

export const LandingpageView = () => {
  const [data, { state, msg }] = useCmsData('/landingpage?populate=deep', mapLandingpageResponse);
  const { hash, key } = useLocation();

  // navigate to a local link once CMS data is fetched
  useEffect(() => {
    if (state !== BasicState.Success) return;
    if (!hash) return;
    setTimeout(() => {
      const el = document.getElementById(hash.substring(1));
      if (el) el.scrollIntoView();
    }, 0);
  }, [state, hash, key]);

  return (
    <Container>
      <BackToTop />
      <Helmet>
        <title>Stephanie Piotrowski — Forensisch-psychologische Gutachten im Strafverfahren.</title>
        <meta
          name="description"
          content="Forensisch-psychologische Gutachten im Strafverfahren. Unabhängig. Kompetent."
        />
        <meta
          name="keywords"
          content="forensische Gutachten, psychologische Gutachten, Stephanie Piotrowski"
        />
      </Helmet>

      {state === BasicState.Error && (
        <Container>
          <Section first>
            <EmptyState>{msg}</EmptyState>
          </Section>
        </Container>
      )}
      {state === BasicState.Success && (
        <>
          {/* hero */}
          <Hero first>
            <HeroImg src={portrait} alt="Portrait von Stephanie Piotrowski" />
            <HeroTitleWrapper>
              <HeroTitle>{data.hero_text}</HeroTitle>
            </HeroTitleWrapper>
            <ScrollDownArrow />
          </Hero>

          {/* competence */}
          <SectionAnimated id="taetigkeitsschwerpunkte">
            <H2 withUnderline>Tätigkeits&shy;schwerpunkte</H2>

            {data.competences.map((item, index) => (
              <NumberedLink key={index} index={`0${index + 1}`} to={`/kompetenz/${item.id}`}>
                {item.title}
              </NumberedLink>
            ))}
          </SectionAnimated>

          {/* about */}
          <SectionAnimated id="zur-person">
            <H2 withUnderline>Zur Person</H2>
            <div className="m-top_xl">
              {data.vitaItems.map((item, index) => (
                <VitaItem key={index} item={item} />
              ))}
            </div>
          </SectionAnimated>

          {/* qm */}
          <SectionAnimated id="qualitaetssicherung">
            <H2 withUnderline>Qualitäts&shy;sicherung</H2>
            <Secondary>
              <TextContainer>{data.qmItems.intro}</TextContainer>
            </Secondary>

            {data.qmItems.qm_items.map((item, index) => (
              <QmItem key={index} href={item.link}>
                {item.title}
              </QmItem>
            ))}
          </SectionAnimated>

          {/* contact */}
          <ContactSection />
        </>
      )}
    </Container>
  );
};

const Hero = styled(SectionAnimated)`
  ${media(breakpoint.tablet)} {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 1rem;
    margin: 3rem 0;
  }

  ${media(breakpoint.desktop)} {
    gap: 2rem;
  }
`;

const HeroImg = styled.img`
  display: block;
  max-width: 280px;
  width: 280px;
  height: 280px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: -1.25rem;
  opacity: 0.92;
  transform: rotate(12deg);

  ${media(breakpoint.mobile)} {
    margin-left: 0;
  }

  ${media(breakpoint.tablet)} {
    margin: 0;
    max-width: 340px;
    width: 340px;
    height: 340px;
  }

  ${media(breakpoint.desktop)} {
    max-width: 380px;
    width: 380px;
    height: 380px;
  }
`;

const HeroTitleWrapper = styled.div`
  margin-top: -5rem;

  ${media(breakpoint.tablet)} {
    margin-top: 0;
  }
`;

const HeroTitle = styled(H1)`
  display: inline;
  background: white;
  padding-right: 0.5rem;
  box-decoration-break: clone;
  font-size: 2.5rem;
`;
