import { css } from '@emotion/react';
import { breakpoint, font } from './config';
import { media } from './helper';

// generate classes like .m-top_xl { margin-top: 4rem; }
const sizingClasses = ['top', 'right', 'bottom', 'left']
  .map((side) =>
    [
      ['xs', '.25rem'],
      ['sm', '.5rem'],
      ['', '1rem'],
      ['lg', '2rem'],
      ['xl', '4rem'],
    ]
      .map(([size, val]) => {
        const sizeSuffix = size ? `_${size}` : '';
        return `.m-${side + sizeSuffix} { margin-${side}: ${val}; }`;
      })
      .join('\n')
  )
  .join('');

export const globalStyles = css`
  * {
    position: relative;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html,
  body {
    ${font.body};
    scroll-behavior: smooth;

    ${media(breakpoint.tablet)} {
      font-size: 18px;
    }
  }

  #root {
    overflow-x: hidden;
  }

  ${sizingClasses}

  /* inter-regular - latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: local('Inter-Regular'), local('Inter Regular'),
      url('/fonts/inter-v11-latin-regular.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/inter-v11-latin-regular.woff')
        format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* inter-600 - latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: local('Inter-SemiBold'), local('Inter SemiBold'),
      url('/fonts/inter-v11-latin-600.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/inter-v11-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* inter-700 - latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: local('Inter-Bold'), local('Inter Bold'),
      url('/fonts/inter-v11-latin-700.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/inter-v11-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
`;
