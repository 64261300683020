import styled from '@emotion/styled';
import { breakpoint } from '../ui/config';
import { H3, Small, StyledLink } from '../ui/modules/atoms';
import React from 'react';
import { media } from '../ui/helper';
import { TextContainer } from '../ui/modules/grid';

interface NumberedLinkProps {
  to: string;
  index: string;
}

export const NumberedLink: React.FC<NumberedLinkProps> = ({ to, index, children }) => {
  return (
    <Wrapper>
      <Index>{index}.</Index>
      <Title>
        <StyledLink to={to}>{children}</StyledLink>
      </Title>
    </Wrapper>
  );
};
const Wrapper = styled(TextContainer)`
  margin: 3rem 0;
`;

const Index = styled(Small)`
  ${media(breakpoint.desktop)} {
    position: absolute;
    top: 0.55rem;
    left: -0.8rem;
    transform: translateX(-100%);
  }
`;

const Title = styled(H3)`
  cursor: pointer;
`;
