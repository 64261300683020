import styled from '@emotion/styled';
import { useState } from 'react';
import { URL_API } from '../config';
import { Button } from '../ui/components/Button';
import { Secondary } from '../ui/modules/atoms';
import { FormRow } from '../ui/modules/form/form-grid';
import { Textarea, Textbox } from '../ui/modules/form/Textbox';
import { Subsection } from '../ui/modules/grid';
import { parseResponseAsJson, postJson } from '../util/resource';
import { BasicState, useStateMachine } from '../util/useStateMachine';

interface IContactFormData {
  name: string;
  emailOrPhone: string;
  message: string;
}

const getEmptyContactFormData = () =>
  ({
    name: '',
    emailOrPhone: '',
    message: '',
  } as IContactFormData);

export const ContactForm: React.FC = () => {
  const [formValues, setFormValues] = useState(getEmptyContactFormData());
  const { state, msg, nextState } = useStateMachine(BasicState.Initial);

  const handleChange = (ev: any) => {
    setFormValues({ ...formValues, [ev.target.name]: ev.target.value });
  };

  const handleSubmit = (ev: any) => {
    ev.preventDefault();
    nextState(BasicState.Loading, 'Ihre Nachricht wird gesendet...');

    postJson<IContactFormData, void>(`${URL_API}/contact`, formValues)
      .then(() => {
        nextState(BasicState.Success, 'Ihre Nachricht wurde erfolgreich abgesendet.');
        setFormValues(getEmptyContactFormData());
      })
      .catch(async (res) => {
        nextState(
          BasicState.Error,
          'Ihre Nachricht konnte leider nicht abgesendet werden. Bitter versuchen Sie es zu einem späteren Zeitpunkt noch einmal.'
        );

        const err = await parseResponseAsJson(res, true);
        console.error(err);
      });
  };

  const formFieldsDisabled = state === BasicState.Loading;

  return (
    <StyledContactForm onSubmit={handleSubmit}>
      <Subsection>
        <FormRow>
          <Textbox
            label="Name"
            name="name"
            value={formValues.name}
            onChange={handleChange}
            disabled={formFieldsDisabled}
            required
          />
        </FormRow>
        <FormRow>
          <Textbox
            label="Email oder Telefonnummer für Rückmeldung"
            name="emailOrPhone"
            value={formValues.emailOrPhone}
            onChange={handleChange}
            disabled={formFieldsDisabled}
            required
          />
        </FormRow>
        <FormRow>
          <Textarea
            label="Ihre Nachricht"
            name="message"
            value={formValues.message}
            onChange={handleChange}
            disabled={formFieldsDisabled}
            required
          />
        </FormRow>
        <FormRow className="m-top_lg">
          <Button type="submit" disabled={formFieldsDisabled}>
            Nachricht absenden
          </Button>
          {state !== BasicState.Initial && <Secondary className="m-top">{msg}</Secondary>}
        </FormRow>
      </Subsection>
    </StyledContactForm>
  );
};

const StyledContactForm = styled.form`
  display: block;
  flex: 1 1 50%;
  max-width: 680px;
`;
