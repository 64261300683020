import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';
import { color } from '../config';
import { FlexRow } from './grid';

interface ITabsProps {
  tabLabels: string[];
  initialTab?: number;
}
export const TabContainer: React.FC<ITabsProps> = ({ tabLabels, initialTab, children }) => {
  const [currentTab, setCurrentTab] = useState(initialTab || 0);
  const [tabHighlightTransform, setTabHighlightTransform] = useState('');
  const refs = useRef([] as HTMLButtonElement[]);

  const showTab = (index: number) => {
    setCurrentTab(index);
  };

  useEffect(() => {
    const currentBtn = refs.current[currentTab];
    if (!currentBtn) return;
    const offsetLeft = currentBtn.offsetLeft;
    const width = currentBtn.clientWidth;
    const scale = Math.max(width / 100, 0.25);
    const transform = `translateX(${offsetLeft}px) scaleX(${scale})`;
    setTabHighlightTransform(transform);
  }, [refs, currentTab]);

  return (
    <div>
      <FlexRow>
        {tabLabels.map((label, i) => (
          <TabButton
            key={i}
            onClick={() => showTab(i)}
            ref={(ref) => ref && (refs.current[i] = ref)}
            type="button"
          >
            {label}
          </TabButton>
        ))}
        <TabHighlightBar style={{ transform: tabHighlightTransform }} />
      </FlexRow>
      <TabViewport>
        <Tabs align="flex-start" style={{ transform: `translateX(${currentTab * -110}%)` }}>
          {children}
        </Tabs>
      </TabViewport>
    </div>
  );
};

const TabHighlightBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100px;
  height: 3px;
  background: ${color.brand.primary};
  transform: scale(1);
  transform-origin: left center;
  transition: transform 0.3s, width 0.3s;
`;

const TabButton = styled.button`
  font: inherit;
  border: none;
  background: none;
  outline: none;
  box-shadow: none;
  color: inherit;
  cursor: pointer;

  font-weight: 600;
  padding: 0 0 0.1rem;
  & + & {
    margin-left: 1rem;
  }
`;

const TabViewport = styled.div`
  max-width: 100%;
`;

const Tabs = styled(FlexRow)`
  transform: translateX(0);
  transition: transform 0.3s;
`;

export const Tab = styled.div`
  min-width: 100%;

  & + & {
    margin-left: 10%;
  }
`;
