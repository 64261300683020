import styled from '@emotion/styled';
import { color } from '../config';
import caretDown from '../../img/caret-up_light.svg';

export const BackToTop: React.FC = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };
  return (
    <StyledBackToTop type="button" onClick={scrollToTop} aria-label="Back to top">
      <Caret src={caretDown} alt="A caret pointing upwards" />
    </StyledBackToTop>
  );
};

const StyledBackToTop = styled.button`
  border: none;
  outline: none;
  font: inherit;

  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 100;
  cursor: pointer;

  border-radius: 1000px;
  color: ${color.text.light};
  background-color: ${color.text.meta};
  transition: background-color 0.15s;

  &:hover {
    background-color: ${color.text.primary};
  }
`;

const Caret = styled.img`
  display: block;
  width: 18px;
  height: 10px;
  transform: rotate(180deg);
`;
