import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { breakpoint } from '../config';
import { media } from '../helper';
import arrowDown from '../../img/arrow-down.svg';
import { EmotionHtmlProps } from '../ui.types';

export const ScrollDownArrow: React.FC<EmotionHtmlProps<HTMLImageElement>> = (props) => {
  return (
    <WrapperA href="#taetigkeitsschwerpunkte">
      <StyledScrollDownArrow src={arrowDown} alt="arrow pointing down" {...props} />
    </WrapperA>
  );
};

const WrapperA = styled.a`
  ${media(breakpoint.mobile)} {
    display: none;
  }
`;

const scrollDown = keyframes`
  0%, 50% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(1rem);
  }
`;

const StyledScrollDownArrow = styled.img`
  display: block;
  width: 46px;
  height: 43px;
  text-align: center;
  margin: 4rem auto;
  padding: 0.2rem 1rem;
  cursor: pointer;
  animation: ${scrollDown} 4s ease infinite;
`;
