import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { breakpoint, color, font } from '../config';
import { media } from '../helper';

export const H1 = styled.h1`
  ${font.h1};

  ${media(breakpoint.tablet)} {
    font-size: 2.8rem;
  }

  ${media(breakpoint.desktop)} {
    font-size: 3.375rem;
  }
`;

export const H2 = styled.h2<{ withUnderline?: boolean }>`
  ${font.h2};

  ${(props) =>
    props.withUnderline &&
    css`
      margin: 2rem 0 0;

      &:after {
        content: '';
        display: block;
        width: 3.5rem;
        height: 0.4rem;
        margin: 1.5rem 0 2rem;
        background: ${color.brand.primary};
      }

      ${media(breakpoint.desktop)} {
        &:after {
          position: absolute;
          bottom: 0.85rem;
          left: -0.8rem;
          width: 2rem;
          margin: 0;
          transform: translateX(-100%);
        }
      }
    `}
`;

export const H3 = styled.h3`
  ${font.h3};
`;

export const H4 = styled.h4`
  ${font.h4};
  margin-top: 1.5rem;
`;

export const P = styled.p`
  margin-bottom: 1.5rem;
`;

export const Secondary = styled.div`
  color: ${color.text.secondary};
`;

export const Strong = styled.strong`
  font-weight: 600;
`;

export const Small = styled.small`
  ${font.small};
`;

export const Smaller = styled.small`
  ${font.smaller};
`;

export const Bigger = styled.span`
  ${font.bigger};
  color: ${color.text.secondary};
`;

const linkStyles = css`
  color: inherit;
  text-decoration: none;

  padding-bottom: 0.15em;
  background-image: linear-gradient(to top, ${color.text.primary} 0.15em, transparent 0);
`;

export const StyledLink = styled(Link)`
  ${linkStyles};
`;

export const StyledA = styled.a`
  ${linkStyles}
`;

export const CmsPre = styled.pre`
  font: inherit;
  max-width: 100%;
`;

export const EmptyState = styled.div`
  font-style: italic;
  color: ${color.text.secondary};
`;
