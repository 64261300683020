import styled from '@emotion/styled';
import { breakpoint, color } from '../ui/config';
import { media } from '../ui/helper';
import { H3, Secondary, Small, Strong } from '../ui/modules/atoms';
import { VitaItemDto } from './landingpage.types';

interface VitaItemProps {
  item: VitaItemDto;
}

export const VitaItem: React.FC<VitaItemProps> = ({ item }) => {
  const { from, to, title, description } = item;

  return (
    <StyledVitaItem>
      {from && (
        <DateContainer>
          <Strong>{from}</Strong> <YearEnd>bis {to || 'heute'}</YearEnd>
        </DateContainer>
      )}
      <H3>{title}</H3>
      {description && <Secondary>{description}</Secondary>}
    </StyledVitaItem>
  );
};

const StyledVitaItem = styled.div`
  padding: 0 0 2.5rem 1rem;
  border-left: 2px solid ${color.text.primary};

  // move subsequent items up, so the border line connects well
  &:not(:first-of-type) {
    margin-top: -0.5rem;
  }
  &:last-child {
    border-left-color: transparent;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: -9px;
    width: 8px;
    height: 8px;
    border-radius: 100px;
    background: ${color.text.primary};
    border: 4px solid ${color.text.light};
  }

  ${media(breakpoint.tablet)} {
    margin-left: 5rem;

    &:before {
      top: 11px;
    }
  }

  ${media(breakpoint.desktop)} {
    &:before {
      top: 14px;
    }
  }
`;

const DateContainer = styled.div`
  ${media(breakpoint.tablet)} {
    position: absolute;
    right: calc(100% + 1rem);
    top: 0;
    width: 5rem;
    text-align: right;
  }
`;

const YearEnd = styled(Small)`
  display: inline-block;
  color: ${color.text.meta};

  ${media(breakpoint.tablet)} {
    display: block;
  }
`;
