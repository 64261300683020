import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { color, font } from '../config';
import { Container, FlexRow } from '../modules/grid';

export const PageFooter: React.FC = () => {
  return (
    <StyledFooter>
      <Container>
        <FlexRow>
          <FooterLink to="/impressum" className="m-right">
            Impressum
          </FooterLink>
          <FooterLink to="/datenschutz">Datenschutz</FooterLink>
        </FlexRow>
      </Container>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  padding: 1rem 0;

  ${font.smaller};
  color: ${color.text.secondary};
`;

const FooterLink = styled(Link)`
  display: inline-block;
  color: inherit;
  text-decoration: none;

  color: ${color.text.secondary};
  transition: color 0.1s;

  &:hover {
    color: ${color.text.primary};
  }
`;
