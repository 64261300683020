import styled from '@emotion/styled';
import { CmsPre, EmptyState, H4, StyledA } from '../ui/modules/atoms';
import { Section, Subsection } from '../ui/modules/grid';
import { ContactInfoDto } from '../landingpage/landingpage.types';
import { useCmsData } from '../cms/useCmsData';
import { BasicState } from '../util/useStateMachine';

export const ContactInfo: React.FC = () => {
  const [contactInfo, { state }] = useCmsData<ContactInfoDto>('/contact-info');

  if (state !== BasicState.Success)
    return (
      <Section first>
        <EmptyState>Lade Kontaktinformationen...</EmptyState>
      </Section>
    );

  return (
    <div>
      <Subsection>
        <H4>{contactInfo.name}</H4>
        <table>
          <tbody>
            <tr>
              <BasicTd>Büro</BasicTd>
              <BasicTd>
                <StyledA href={`tel:${contactInfo.phone_office}`}>
                  {contactInfo.phone_office}
                </StyledA>
              </BasicTd>
            </tr>
            <tr>
              <BasicTd>Mobil</BasicTd>
              <BasicTd>
                <StyledA href={`tel:${contactInfo.phone_mobile}`}>
                  {contactInfo.phone_mobile}
                </StyledA>
              </BasicTd>
            </tr>
          </tbody>
        </table>

        <StyledA href={`mailto:${contactInfo.email}`}>{contactInfo.email}</StyledA>
      </Subsection>
      <Subsection>
        <H4>Ladungsfähige Anschrift:</H4>
        <CmsPre>{contactInfo.address_court}</CmsPre>
      </Subsection>
      <Subsection>
        <H4>Für Paketsendungen:</H4>
        <CmsPre>{contactInfo.address_post}</CmsPre>
      </Subsection>
    </div>
  );
};

const BasicTd = styled.td`
  & + & {
    padding-left: 1rem;
  }
`;
