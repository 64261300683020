import { useEffect, useState } from 'react';
import { URL_CMS } from '../config';
import { readJson } from '../util/resource';
import { BasicState, StateMachine, useStateMachine } from '../util/useStateMachine';
import { StrapiResponse } from './cms.types';

const cache = {} as any;

/**
 * Hook to fetch data from CMS.
 * @param url - relative API url. Example: '/landingpage' will fetch ${URL_API}/landingpage.
 * @returns an Array containing the data and a StateMachine<BasicState> indication fetch state.
 */
export function useCmsData<T>(
  url: string,
  fnMapResponse?: (data: any) => T
): [T, StateMachine<BasicState>] {
  const [data, setData] = useState({} as T);
  const { state, nextState, msg } = useStateMachine(BasicState.Initial);

  function handleCmsFetch<T extends StrapiResponse<any>>(fetchPromise: Promise<T>) {
    fetchPromise
      .then((data) => {
        let resData = data?.data?.attributes;
        if (!resData) return Promise.reject(data);
        if (fnMapResponse) resData = fnMapResponse(resData);
        cache[url] = resData;
        setData(resData);
        nextState(BasicState.Success);
      })
      .catch((err) => {
        console.error(err);
        nextState(
          BasicState.Error,
          'Diese Seite konnte leider nicht geladen werden. Bitte versuchen Sie, die Seite erneut zu laden.'
        );
      });
  }

  useEffect(() => {
    // check if request is already cached
    const cached = cache[url];
    if (cached) {
      // cached is a promise -> add handler
      if (cached.then) {
        nextState(BasicState.Loading);
        handleCmsFetch(cached);
      } else {
        // cached is data -> map and set data
        setData(cached);
        nextState(BasicState.Success);
      }
      return;
    }

    // not cached -> fetch
    nextState(BasicState.Loading, 'Lade...');
    const p = readJson<StrapiResponse<T>>(URL_CMS + url);
    cache[url] = p;
    handleCmsFetch(p);
  }, [url, nextState]); // eslint-disable-line react-hooks/exhaustive-deps

  return [data, { state, nextState, msg }];
}
