import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useCmsData } from '../cms/useCmsData';
import { ContactTabs } from '../contact/ContactTabs';
import { CompetenceItem, ContactInfoDto } from '../landingpage/landingpage.types';
import { BackToTop } from '../ui/components/BackToTop';
import { SectionAnimated } from '../ui/components/SectionAnimated';
import { Bigger, EmptyState, H2, Secondary } from '../ui/modules/atoms';
import { Container, Section, Subsection, TextContainer } from '../ui/modules/grid';
import { BasicState } from '../util/useStateMachine';

export const CompetencePage: React.FC = () => {
  const { id } = useParams<any>();
  const [competence, { state, msg }] = useCmsData<CompetenceItem>(`/competences/${id}`);
  const [contactInfo] = useCmsData<ContactInfoDto>(`/contact-info`);

  const getContent = () => {
    if (state !== BasicState.Success)
      return (
        <Section first>
          <EmptyState>{msg}</EmptyState>
        </Section>
      );

    return (
      <>
        <Helmet>
          <title>
            {competence.seo_title} — Forensisch-psychologische Gutachten im Strafverfahren.
          </title>
          <meta name="description" content={competence.description} />
          <meta name="keywords" content={competence.seo_title} />
        </Helmet>
        <Section first>
          <H2>{competence.title}</H2>
          <Subsection>
            <Bigger>{competence.description}</Bigger>
          </Subsection>
        </Section>
        <SectionAnimated>
          <H2 withUnderline>Gutachten beauftragen</H2>
          <Secondary>
            <TextContainer>{contactInfo.intro_competence}</TextContainer>
          </Secondary>
          <ContactTabs />
        </SectionAnimated>
      </>
    );
  };

  return (
    <Container>
      <BackToTop />

      {getContent()}
    </Container>
  );
};
